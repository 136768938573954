/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { f, ln } from 'src/components/common/Typography'

import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import { Squidex_TeamMemberFlatDataDto } from 'src/types/gatsby-types'
import { mq } from 'src/utils/mq'
import { theme } from 'src/utils/theme'

const wrapperStyles = css`
  font-size: ${f(20)};
  line-height: ${ln(20, 34)};
  color: ${theme.colors.primaryText};
  display: inline-flex;
  margin-bottom: 8px;
  align-items: center;
  margin-right: 24px;
  margin-bottom: 24px;

  a {
    color: inherit;
    text-decoration: none;
    display: inline-block;

    &:hover {
      text-decoration: underline;
    }
  }

  ${mq.md} {
    margin-bottom: 0;
    margin-right: 48px;
  }
`

const AuthorImageStyled = css`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 12px;
`

const NameWrapperStyles = css`
  line-height: 1em;

  small {
    opacity: 0.7;
  }
`

export const ArticlePageMetaAuthor: React.FC<{
  authors: Squidex_TeamMemberFlatDataDto[]
}> = ({ authors }) => {
  return (
    <React.Fragment>
      {authors.map((author) => {
        const imgData =
          author.photo?.[0]?.imageFile?.childImageSharp?.gatsbyImageData

        return (
          <div css={wrapperStyles} key={author.name || ''}>
            {imgData ? (
              <GatsbyImage
                image={imgData}
                alt={author.name || 'Author'}
                css={AuthorImageStyled}
              />
            ) : null}
            <div css={NameWrapperStyles}>
              <div>{author.name}</div>
              <small>{author.position}</small>
            </div>
          </div>
        )
      })}
    </React.Fragment>
  )
}

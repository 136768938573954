/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { f, ln } from 'src/components/common/Typography'

import { mq } from 'src/utils/mq'
import { theme } from 'src/utils/theme'
import { useLocation } from '@reach/router'

const clapButtonWrapper = css`
  display: flex;
  align-items: center;
  margin-top: 48px;
  justify-content: center;
  margin-bottom: 24px;

  ${mq.xxl} {
    width: 58px;
    position: sticky;
    margin-left: -120px;
    bottom: 120px;
    margin-top: -70px;
    margin-bottom: 24px;
    flex-direction: column-reverse;
  }
`

const applaudesLabel = css`
  color: ${theme.colors.primaryText};
  font-size: ${f(20)};
  line-height: ${ln(20, 34)};
  padding-right: 1em;
  font-weight: bold;
  white-space: nowrap;

  ${mq.xxl} {
    margin-top: 10px;
    font-size: ${f(14)};
    line-height: ${ln(14, 20)};
  }
`

export const ClapButton: React.FC = () => {
  const { pathname } = useLocation()
  const isSSR = typeof window === 'undefined'

  return (
    <div css={clapButtonWrapper}>
      <style>
        {`
          applause-button .count-container .count {
            color: #202455;
            font-weight: bold;
          }
        `}
      </style>
      <div css={applaudesLabel}>Clap if you like it!</div>
      {!isSSR && (
        /* @ts-ignore */
        <applause-button
          multiclap
          color="#202455"
          style={{
            width: '58px',
            height: '58px',
            cursor: 'pointer',
            background: 'rgba(255, 255, 255, .9)',
          }}
          url={document.location.origin + pathname}
        />
      )}
    </div>
  )
}

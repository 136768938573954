/** @jsx jsx */

import { css, jsx } from '@emotion/react'

import { GatsbyImage } from 'gatsby-plugin-image'
import type { Squidex_Asset } from 'src/types/gatsby-types'

type Props = {
  slide: Squidex_Asset
  title: string
}

const wrapperStyles = css`
  position: relative;
  overflow: hidden;
  border-radius: 5px;
`
const imageStyles = css`
  width: 100%;
  height: auto;
  display: block;
  border-radius: 5px;
  min-height: 160px;
`

export const SliderItem: React.FC<Props> = ({ slide, title }) => {
  const imgSrc = slide.imageFile?.childImageSharp?.gatsbyImageData

  if (!imgSrc) {
    return null
  }

  return (
    <div css={wrapperStyles}>
      <GatsbyImage css={imageStyles} image={imgSrc} alt={title} />
    </div>
  )
}

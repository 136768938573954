/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { f, ln } from 'src/components/common/Typography'

import { mq } from 'src/utils/mq'
import { theme } from 'src/utils/theme'

const wrapperStyles = css`
  font-size: ${f(16)};
  line-height: ${ln(16, 21)};
  color: ${theme.colors.grayText};
  display: inline-block;
  margin-bottom: 8px;
  margin-right: 24px;

  a {
    color: inherit;
    text-decoration: none;
    display: inline-block;

    &:hover {
      text-decoration: underline;
    }
  }

  ${mq.md} {
    margin-right: 48px;
  }

  svg {
    vertical-align: text-top;
    margin-right: 4px;
  }
`

type Props = {
  label: string
  value: React.ReactElement | string
  icon?: React.ReactElement
}

export const ArticlePageMetaItem: React.FC<Props> = ({
  label,
  value,
  icon,
}) => {
  return (
    <div css={wrapperStyles}>
      {icon}
      {`${label}: `}
      <span style={{ whiteSpace: 'nowrap' }}>{value}</span>
    </div>
  )
}

/** @jsx jsx */

import { css, jsx } from '@emotion/react'

import { ButtonLink } from 'src/components/Button/Button'
import React from 'react'
import { Squidex_Article } from 'src/types/gatsby-types'
import { path } from 'src/utils/path'
import styled from '@emotion/styled'

const $Wrapper = styled.div`
  background: #212950;
  border-radius: 10px;
  padding: 24px 24px 12px;
  max-width: 700px;
  margin: 0 auto;
  margin: 24px 0;

  p {
    font-size: 1.1em;
    color: white;
    font-weight: bold;
  }
`

const $Specialist = styled.p`
  line-height: 32px;
  font-size: 1.3em !important;

  a {
    font-size: 16px;
    margin-top: 16px;
    width: 100%;
    background: #dd431c;

    &:hover {
      background: #ff4700;
    }
  }
`

const avatarImageStyles = css`
  margin: 0 4px 0 8px;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  vertical-align: middle;
`
type Props = {
  article?: Squidex_Article
}

export const ArticlePageAdvise: React.FC<Props> = ({ article }) => {
  return (
    <$Wrapper>
      <p>
        {article?.flatData.sidebarTitleCTA ||
          'Get in touch to discuss your business idea'}
      </p>
      <$Specialist>
        {article?.flatData.sidebarContactPerson?.map(
          (item) => item.flatData.name,
        )}
        {article?.flatData.sidebarContactPerson?.map((item) =>
          item.flatData.photo?.map((photo) => (
            <img
              key={photo.id}
              src={photo.url}
              width={photo.url}
              height={32}
              alt={photo.id}
              css={avatarImageStyles}
            />
          )),
        )}

        <ButtonLink
          to={article?.flatData.sidebarLinkHref || path.contactUs}
          variant="contained"
          size="normal"
        >
          {article?.flatData.sidebarLinkText || "Let's Start"}
        </ButtonLink>
      </$Specialist>
    </$Wrapper>
  )
}

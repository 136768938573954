/** @jsx jsx */

import ReactSyntaxHighlighter, {
  PrismLight as SyntaxHighlighter,
} from 'react-syntax-highlighter'
import { contentText, f, ln } from 'src/components/common/Typography'
import { css, jsx } from '@emotion/react'

import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Squidex_ArticleDataDto } from 'src/types/gatsby-types'
import { TocContext } from 'src/context/TocContext'
import bash from 'react-syntax-highlighter/dist/cjs/languages/prism/bash'
import { breakpoints } from 'src/Media'
import { convertMediaNameToLocalName } from 'src/utils/convert-media-url-to-local-name'
import javascript from 'react-syntax-highlighter/dist/cjs/languages/prism/javascript'
import json from 'react-syntax-highlighter/dist/cjs/languages/prism/json'
import markdown from 'react-syntax-highlighter/dist/cjs/languages/prism/markdown'
import { mq } from 'src/utils/mq'
import rangeParser from 'parse-numeric-range'
import rehypeRaw from 'rehype-raw'
import rehypeSlug from 'rehype-slug'
import remarkBreaks from 'remark-breaks'
import remarkGFM from 'remark-gfm'
import scss from 'react-syntax-highlighter/dist/cjs/languages/prism/scss'
import sh from 'react-syntax-highlighter/dist/cjs/languages/prism/bash'
import { monoBlue as syntaxTheme } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import { theme } from 'src/utils/theme'
import tsx from 'react-syntax-highlighter/dist/cjs/languages/prism/tsx'
import typescript from 'react-syntax-highlighter/dist/cjs/languages/prism/typescript'
import useEvent from '@react-hook/event'

SyntaxHighlighter.registerLanguage('tsx', tsx)
SyntaxHighlighter.registerLanguage('typescript', typescript)
SyntaxHighlighter.registerLanguage('scss', scss)
SyntaxHighlighter.registerLanguage('bash', bash)
SyntaxHighlighter.registerLanguage('markdown', markdown)
SyntaxHighlighter.registerLanguage('json', json)
SyntaxHighlighter.registerLanguage('sh', sh)
SyntaxHighlighter.registerLanguage('javascript', javascript)
SyntaxHighlighter.registerLanguage('js', javascript)

type Props = Required<Pick<Squidex_ArticleDataDto, 'content'>>

const isAbsolute = (href: string): boolean =>
  href?.startsWith('https:') || href?.startsWith('http:')

const isExternalLink = (href: string): boolean =>
  isAbsolute(href) &&
  !href.includes('http://localhost:8000') &&
  !href.includes('http://127.0.0.1:8000/') &&
  !href.includes('https://rabbitpeepers.com/')

const wrapperStyles = css`
  ${contentText}
  color: ${theme.colors.black};
  font-weight: 400;
  line-height: ${ln(18, 32)};
  font-size: ${f(18)};

  ${mq.md} {
    font-weight: 300;
    line-height: ${ln(21, 36)};
    font-size: ${f(21)};
  }

  ${mq.lg} {
    p > a > img,
    p > img,
    .display-as-paragraph > a > img,
    .display-as-paragraph > img {
      max-width: 80%;
      margin-left: 10%;
      margin-right: 10%;
    }
  }

  table {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  & {
    margin: 12px 0;
  }

  p,
  .display-as-paragraph {
    margin: 32px 0;
  }

  & ol {
    margin: 16px 0;
  }

  & ul {
    margin: 16px 0;
    list-style-type: none;
    padding: 0;
  }

  & ul li {
    margin: 16px 0;
    padding: 0 0 0 24px;
    position: relative;
    display: block;
  }

  & ol li {
    margin: 16px 0;
  }

  ${mq.lg} {
    & ul li {
      margin: 24px 0;
    }

    & ol li {
      margin: 24px 0;
    }
  }

  h1 {
    margin: 96px 0 36px;
  }

  h2 {
    margin: 64px 0 24px;
  }

  h3 {
    margin: 48px 0 24px;
  }

  h4,
  h5,
  h6 {
    margin: 36px 0 18px;
  }

  & ul li:before {
    top: 19px;
  }
`

export const ArticlePageContent: React.FC<Props> = ({ content }) => {
  const ref = React.useRef<HTMLElement>()
  const [headingTop, setHeadingTop] = React.useState<Record<number, string>>(
    () => ({}),
  )

  const { toc, setCurrent } = React.useContext(TocContext)

  React.useLayoutEffect(() => {
    const haveIds = ref.current?.querySelectorAll('[id]')
    const sizes: Record<number, string> = {}

    haveIds?.forEach((item) => {
      if (toc.find((i) => i.link === `#${item.id}`)) {
        sizes[(item as HTMLElement).offsetTop] = item.id
      }
    })

    setHeadingTop(sizes)
  }, [ref, setHeadingTop, toc])

  useEvent(typeof window !== 'undefined' ? window : null, 'scroll', () => {
    if (window?.innerWidth < breakpoints.lg) {
      return
    }

    const positions = Object.keys(headingTop).map((i) => parseInt(i, 10))
    const scroll = window.scrollY
    const current = positions
      .sort((a, b) => a - b)
      .find((item) => item > scroll)
    if (!current) {
      return
    }

    const id = headingTop[current]

    setCurrent(id)
  })

  return (
    <article css={wrapperStyles} ref={ref as React.LegacyRef<HTMLElement>}>
      <ReactMarkdown
        linkTarget={(href: string) => (isAbsolute(href) ? '_blank' : '')}
        remarkPlugins={[remarkGFM, remarkBreaks]}
        rehypePlugins={[rehypeSlug, rehypeRaw]}
        components={{
          p: ({ children }) => {
            if (
              children.find((item) => (item as React.ReactElement)?.props?.src)
            ) {
              return <div className="display-as-paragraph">{children}</div>
            }

            return <p>{children}</p>
          },
          table: ({ children }) => (
            <div style={{ overflowX: 'auto' }}>
              <table style={{ minWidth: '768px' }}>{children}</table>
            </div>
          ),
          a: ({ node, ...props }) => {
            // eslint-disable-next-line no-param-reassign
            props.rel = isExternalLink(props.href || '')
              ? 'nofollow'
              : props.rel
            return (
              <a rel="nofollow" {...props}>
                {props.children}
              </a>
            )
          },
          img: ({ node, ...props }) => {
            if (!props.src) {
              return <img />
            }

            const name = convertMediaNameToLocalName(props.src)

            const gatsbyImage = content?.assets?.images?.find(
              (i) => name === i?.name,
            )?.childImageSharp?.gatsbyImageData

            if (gatsbyImage) {
              return <GatsbyImage image={gatsbyImage} alt={props.alt || ''} />
            }

            return (
              <a
                target="_blank"
                href={props.src}
                title="Open full image"
                rel="noreferrer"
              >
                <img {...props} />
              </a>
            )
          },
          code: ({ node, className, ...props }) => {
            const match = /language-(\w+)/.exec(className || '')
            const hasMeta = node?.data?.meta

            const applyHighlights: object = (applyHighlights: number) => {
              if (hasMeta) {
                const RE = /{([\d,-]+)}/
                const metadata = (node?.data?.meta as string)?.replace?.(
                  /\s/g,
                  '',
                )
                const strlineNumbers = RE?.test(metadata)
                  ? RE?.exec(metadata)?.[1]
                  : '0'
                const highlightLines = rangeParser(strlineNumbers as string)
                debugger
                const highlight = highlightLines
                const data = highlight.includes(applyHighlights)
                  ? 'highlight'
                  : null
                return { data }
              } else {
                return {}
              }
            }

            return match ? (
              <ReactSyntaxHighlighter
                style={syntaxTheme}
                language={match[1]}
                PreTag="div"
                className="codeStyle"
                showLineNumbers={
                  (props.children?.[0]?.toString().split('\n').length || 0) > 5
                }
                wrapLines={hasMeta ? true : false}
                wrapLongLines
                // showInlineLineNumbers
                lineProps={applyHighlights}
                {...(props as { children: string[] })}
              />
            ) : (
              <code className={className} {...props} />
            )
          },
        }}
      >
        {content?.iv || ''}
      </ReactMarkdown>
    </article>
  )
}

/** @jsx jsx */
import { FiAnchor, FiBriefcase, FiCalendar, FiTrendingUp } from 'react-icons/fi'
import { css, jsx } from '@emotion/react'

import { ArticlePageMetaAuthor } from 'src/components/ArticlePage/ArticlePageMetaAuthor'
import { ArticlePageMetaItem } from 'src/components/ArticlePage/ArticlePageMetaItem'
import ExternalLinkSVG from 'src/assets/external-link.svg'
import { Squidex_ArticleFlatDataDto } from 'src/types/gatsby-types'
import moment from 'moment'
import { mq } from 'src/utils/mq'

const wrapperStyles = css`
  padding-bottom: 8px;
  margin-bottom: 40px;
  margin-top: 16px;

  ${mq.md} {
    flex-direction: row;
  }
`

const metaWrapperStyles = css`
  display: flex;
  flex-direction: column;

  ${mq.md} {
    flex-direction: row;
  }
`

const authorsWrapper = css`
  margin-top: 16px;
`

const iconStyles = css`
  width: 18px;
  height: 18px;
  display: inline-block;
  margin-left: 4px;
`

type Props = Readonly<
  Partial<
    Pick<
      Squidex_ArticleFlatDataDto,
      | 'author'
      | 'finishDate'
      | 'link'
      | 'displayPublishDate'
      | 'displayUpdatedDate'
    >
  >
>

export const ArticePageMeta: React.FC<Props> = ({
  author,
  finishDate,
  link,
  displayPublishDate,
  displayUpdatedDate,
}) => {
  const authorName = author?.[0]?.flatData?.name

  return (
    <div css={wrapperStyles}>
      <div css={metaWrapperStyles}>
        {finishDate ? (
          <ArticlePageMetaItem
            label="Finish date"
            value={finishDate}
            icon={<FiAnchor />}
          />
        ) : null}
        {displayPublishDate ? (
          <ArticlePageMetaItem
            label="Published"
            value={moment(displayPublishDate).format('DD MMM YYYY')}
            icon={<FiCalendar />}
          />
        ) : null}
        {displayUpdatedDate ? (
          <ArticlePageMetaItem
            label="Updated"
            value={moment(displayUpdatedDate).format('DD MMM YYYY')}
            icon={<FiTrendingUp />}
          />
        ) : null}
        {link ? (
          <ArticlePageMetaItem
            label="Link"
            value={
              <a href={link} target="_blank" rel="noreferrer">
                {link}
                <ExternalLinkSVG css={iconStyles} />
              </a>
            }
            icon={<FiBriefcase />}
          />
        ) : null}
      </div>
      {authorName ? (
        <div css={authorsWrapper}>
          <ArticlePageMetaAuthor authors={author.map((i) => i.flatData)} />
        </div>
      ) : null}
    </div>
  )
}

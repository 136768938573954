import { Layout, MetaDataProps } from 'src/components/Layout/Layout'
import { PageProps, graphql } from 'gatsby'
import {
  SquidexFindArticleContentArgs,
  Squidex_Article,
} from 'src/types/gatsby-types'

import { ArticlePage } from 'src/components/ArticlePage/ArticlePage'
import { BreadcrumbsLocation } from 'src/components/Breadcrumbs/Breadcrumbs'
import React from 'react'

export type CaseData = {
  squidex: {
    findArticleContent?: Squidex_Article & { flatData: { meta: MetaDataProps } }
  }
}

export type CaseContext = SquidexFindArticleContentArgs

const CaseTemplate: React.FC<PageProps<CaseData, CaseContext>> = ({
  data,
  pageContext,
  location,
}) => {
  const article = data?.squidex?.findArticleContent

  if (!article) {
    throw new Error(`Article data ${pageContext.id} not found`)
  }

  return (
    <Layout
      title={article.flatData.title || 'Case page'}
      meta={article.flatData.meta}
      metaType="article"
    >
      <ArticlePage
        location={location as BreadcrumbsLocation}
        article={article}
      />
    </Layout>
  )
}

export const query = graphql`
  query article($id: String!) {
    squidex {
      findArticleContent(id: $id) {
        flatData {
          author {
            ...Author
          }
          sidebarLinkHref
          sidebarLinkText
          sidebarTitleCTA
          sidebarContactPerson {
            flatData {
              name
              photo {
                id
                url
              }
            }
          }
          content
          description
          slider {
            url
            slug
            id
            metadata(path: "description")
            imageFile {
              childImageSharp {
                gatsbyImageData(height: 659, quality: 95)
              }
            }
          }

          title
          finishDate
          displayPublishDate
          displayUpdatedDate
          link
          tableOfContents
          related {
            id
            created
            flatData {
              typeOfContent
              description
              title
              author {
                ...Author
              }
              preview {
                ...PreviewImage
              }
            }
          }
          ...SiteInformation
        }
        data {
          content {
            iv
            assets {
              images {
                publicURL
                id
                name
                extension
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`

export default CaseTemplate

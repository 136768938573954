/** @jsx jsx */
import { css, jsx } from '@emotion/react'

import { ButtonGroupProps } from 'react-multi-carousel'
import React from 'react'
import { RoundedButton } from 'src/components/common/RoundedButton'

const wrapper = css`
  width: 140px;
  display: flex;
  justify-content: space-between;
`

export const CarouselButtons: React.FC<ButtonGroupProps> = ({ next, previous }) => {
  return (
    <div css={wrapper}>
      <RoundedButton
        aria-label="previous slide"
        tabIndex={0}
        onKeyPress={previous}
        onClick={previous}
        variant="arrow-left"
        size="large"
      />
      <RoundedButton
        aria-label="next slide"
        tabIndex={0}
        onKeyPress={next}
        onClick={next}
        variant="arrow-right"
        size="large"
      />
    </div>
  )
}

/** @jsx jsx */
/* eslint-disable react/jsx-props-no-spreading */
import { css, jsx } from '@emotion/react'

import React from 'react'
import { TocContext } from 'src/context/TocContext'
import styled from '@emotion/styled'
import { useScrollToId } from 'src/components/common/useScrollToId'

const $Wrapper = styled.nav`
  background: #eaf0ff;
  border-radius: 10px;
  padding: 16px;
  max-width: 700px;
  margin: 0 auto;
`

const $List = styled.ul`
  margin: 0;
  padding: 0;
`

const $Item = styled.li`
  border-radius: 10px;
  list-style-type: none;
  margin: 0;
  margin-bottom: 4px;

  &:last-of-type {
    margin-bottom: 0px;
  }

  a {
    display: inline-block;
    padding: 4px 8px;
    color: #202455;
    text-decoration: none;

    &:hover {
      background: rgba(255, 255, 255, 0.8);
      border-radius: 10px;
      color: #ff4700;
    }
  }
`

const haveExtraMargins = css`
  @media (min-height: 680px) {
    a {
      padding: 8px 16px !important;
    }
  }
`

const activeItem = css`
  color: #ff4700 !important;
`

const TocLink: React.FC<MenuLink & { active: boolean }> = ({
  title,
  link,
  active,
}) => {
  const handleScroll = useScrollToId<HTMLAnchorElement>(link)

  return (
    <$Item key={title}>
      <a href={link} onClick={handleScroll} css={active && activeItem}>
        {title}
      </a>
    </$Item>
  )
}

export const ArticlePageToc: React.FC = () => {
  const { current, toc } = React.useContext(TocContext)

  if (!toc?.length) {
    return null
  }

  return (
    <$Wrapper>
      <$List css={toc.length < 10 ? haveExtraMargins : null}>
        {toc.map((i) => (
          <TocLink {...i} active={i.link === `#${current}`} key={i.link} />
        ))}
      </$List>
    </$Wrapper>
  )
}

type MenuLink = {
  title: string
  link: string
}

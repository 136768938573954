import React from 'react'

export const useWindowSize = (): [number, number] => {
  const isBrowser = typeof window !== 'undefined'
  const [width, setWidth] = React.useState(isBrowser ? window.innerWidth : 0)
  const [height, setHeight] = React.useState(isBrowser ? window.innerWidth : 0)

  React.useEffect(() => {
    if (!isBrowser) return undefined

    const handleResize = () => {
      setWidth(window.innerWidth)
      setHeight(window.innerHeight)
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  return [width, height]
}

/** @jsx jsx */

import { ArticlePageAdvise } from 'src/components/ArticlePage/ArticlePageAdvise'
import { ArticlePageToc } from 'src/components/ArticlePage/ArticlePageToc'
import React from 'react'
import { Squidex_Article } from 'src/types/gatsby-types'
import { jsx } from '@emotion/react'
import { mq } from 'src/utils/mq'
import styled from '@emotion/styled'
import useEvent from '@react-hook/event'

const $Sticky = styled.nav`
  ${mq.lg} {
    position: sticky;
    top: 120px;
  }
`

const headerHeight = 120
const adviseHeight = 250
type Props = {
  article: Squidex_Article
}
export const ArticlePageSidebar: React.FC<Props> = ({ article }) => {
  const stickyRef = React.useRef<HTMLElement>(null)
  const [hasSpace, setHasSpace] = React.useState(false)

  const handleResize = React.useCallback(() => {
    if (!stickyRef.current) {
      return
    }

    setHasSpace(
      window.innerHeight >
        stickyRef.current.offsetHeight + headerHeight + adviseHeight,
    )
  }, [setHasSpace, stickyRef])

  React.useEffect(() => {
    handleResize()
  }, [handleResize])

  useEvent(
    typeof window !== 'undefined' ? window : null,
    'resize',
    handleResize,
  )

  return (
    <React.Fragment>
      {!hasSpace ? <ArticlePageAdvise article={article} /> : null}
      <$Sticky ref={stickyRef}>
        <ArticlePageToc />
        {hasSpace ? <ArticlePageAdvise article={article} /> : null}
      </$Sticky>
    </React.Fragment>
  )
}

import React, { useMemo } from 'react'
import { capitalize, uniq } from 'lodash'

import { Link } from 'gatsby'
import type { Maybe } from 'src/types/gatsby-types'
import { path } from 'src/utils/path'
import styled from '@emotion/styled'

interface BreadcrumbsLocationState {
  key: string
}
export interface BreadcrumbsLocation {
  hash: Maybe<string>
  host: string
  hostname: string
  href: string
  key: string
  origin: string
  pathname: string
  port: string
  protocol: string
  search: string
  state: BreadcrumbsLocationState
}

interface Location {
  location: BreadcrumbsLocation
}

export const Flex = styled.div`
  display: flex;
  font-size: 14px;
  color: gray;

  a {
    font-size: 14px;
    color: gray;
  }
`

export const Breadcrumbs: React.FC<Location> = ({ location }) => {
  const locationParts = useMemo(
    () =>
      uniq([
        'RabbitPeepers',
        ...location.pathname.split('/').filter((name) => name !== ''),
      ]),
    [location],
  )

  const lastPage = locationParts.length > 0 ? locationParts.length - 1 : 0

  return (
    <Flex>
      {locationParts.map((page, index) => {
        return (
          <div key={page}>
            {index !== lastPage ? (
              <Flex>
                {index !== lastPage - 1 ? (
                  <>
                    <Link to={index === 0 ? '/' : path.blog}>
                      {index === 0 ? page : capitalize(page)}
                    </Link>
                    <div> &nbsp; / &nbsp;</div>
                  </>
                ) : (
                  <>
                    <Link
                      to={
                        index === 0
                          ? '/'
                          : path.blogCategory(page === 'blog' ? null : page)
                      }
                    >
                      {index === 0 ? page : capitalize(page)}
                    </Link>
                  </>
                )}
              </Flex>
            ) : (
              ''
            )}
          </div>
        )
      })}
    </Flex>
  )
}

/** @jsx jsx */
import 'react-multi-carousel/lib/styles.css'
import './slider.less'

import Carousel from 'react-multi-carousel'
import { CarouselDot } from 'src/components/common/CarouselDot'
import { SliderArrows } from 'src/components/Slider/SliderArrows'
import { SliderItem } from 'src/components/Slider/SliderItem'
import { Squidex_ArticleFlatDataDto } from 'src/types/gatsby-types'
import { breakpoints } from 'src/Media'
import { jsx } from '@emotion/react'
import { useWindowSize } from 'src/utils/useWindowSize'

type Props = Required<Pick<Squidex_ArticleFlatDataDto, 'slider'>>

export const Slider: React.FC<Props> = ({ slider }) => {
  const [windowWidth] = useWindowSize()

  if (!slider) {
    return null
  }

  return (
    <div className="images-carousel">
      <Carousel
        responsive={{
          mobile: {
            breakpoint: {
              max: breakpoints.md,
              min: breakpoints.sm,
            },
            items: 1,
          },
          desktop: {
            breakpoint: {
              min: breakpoints.md,
              max: 10000,
            },
            items: 1,
            partialVisibilityGutter: 0,
          },
        }}
        showDots={true}
        slidesToSlide={1}
        swipeable
        additionalTransfrom={0}
        arrows={windowWidth < 1024}
        focusOnSelect={false}
        centerMode={false}
        minimumTouchDrag={50}
        partialVisible
        infinite
        customDot={<CarouselDot />}
        customButtonGroup={<SliderArrows />}
        autoPlaySpeed={7500}
      >
        {slider.map((i) => (
          <SliderItem slide={i} title={i.metadata} key={i.id} />
        ))}
      </Carousel>
    </div>
  )
}

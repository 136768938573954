/** @jsx jsx */
import { css, jsx } from '@emotion/react'

import { ButtonGroupProps } from 'react-multi-carousel'
import { CarouselButtons } from 'src/components/common/CarouselButtons'
import { Media } from 'src/Media'

const wrapper = css`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
`

export const SliderArrows: React.FC<ButtonGroupProps> = (props) => {
  return (
    <Media greaterThanOrEqual="lg" css={wrapper}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <CarouselButtons {...props} />
    </Media>
  )
}
